// composables/useLazyObserver.ts
export function useLazyObserver(
  targetRef: Ref<HTMLElement | null>,
  callback: () => void,
  options: IntersectionObserverInit = { threshold: 0.1 }
) {
  let observer: IntersectionObserver | null = null

  onMounted(() => {
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          callback()
          observer?.disconnect()
        }
      })
    }, options)

    if (targetRef.value) {
      observer.observe(targetRef.value)
    }
  })

  onBeforeUnmount(() => {
    if (observer) {
      observer.disconnect()
    }
  })
} 